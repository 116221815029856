.output-selector {
  display: flex;
  align-items: center;
  position: relative;
  background-color: $menu-background;
  grid-area: search;
  padding: $space-double;

  .output-selector__menu {
    position: absolute;
    top: 42px;
    left: 0px;
    background-color: $menu-background;
    margin: $space;
    max-width: 254px;
    padding: $space;
    border-radius: 3px;
    z-index: 10;

    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
      0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
      0 32px 16px rgba(0, 0, 0, 0.09);
  }

  .output-selector__item {
    margin: $space;
    width: 30.7px;
    height: 30.7px;
    border-bottom: 2px solid $highlight;
    border-radius: 2px;
    cursor: pointer;

    &[data-selected] {
      border-bottom: 2px solid $highlight-alt;
    }
  }

  .output-selector__menu__item {
    background-color: lighten($menu-background, 10%);
    margin: 2px;
    width: 32px;
    height: 32px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 3px;
    border: 2px solid lighten($menu-background, 10%);

    &[data-selected],
    &:hover {
      border: 2px solid $highlight-alt;
    }
  }

  .output-selector__count {
    height: 32px;
    background-color: $menu-background;
    margin: $space;
    width: 50px;
    flex-grow: 1;
    position: relative;

    input {
      height: 32px;
      font-size: 115%;
      padding: $space-half $space;
      width: 100%;
      color: $light-text;
      background-color: transparent;
      @include input;
    }

    &__suffix {
      position: absolute;
      right: $space-double;
      color: $highlight;
      top: 7px;
      pointer-events: none;
    }
  }

  .output-selector__sort {
    width: 100px;
    height: 32px;
    margin: 5px;
    font-size: 115%;
    background-color: $menu-background;
    color: $light-text;
    @include input;
  }
}

.result-list {
  overflow-x: hidden;
  overflow-y: auto;
  grid-area: results;
  background-color: $menu-background;
  display: none;
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  @include scrollbar($menu-background);

  &[data-is-open] {
    display: block;
  }

  @include medium {
    display: block;
    position: inherit;
  }
}
