@import "normalize.css/normalize.css";

$belt-width: 16px;
$block-size: 64px;

$space: 4px;
$space-double: 8px;
$space-half: 2px;

$belt-border: #989aa4;
$highlight-alt: rgba(135, 179, 141, 1);
$highlight: rgba(51, 101, 138, 1);
$menu-background: rgba(31, 32, 65, 1);
$solution-background: rgb(12, 12, 22);
$black: rgba(7, 7, 7, 1);
$light-text: rgb(235, 233, 221);
$dark-text: $black;

// Small tablets and large smartphones (landscape view)
$screen-small-min: 576px;
@mixin small {
  @media (min-width: #{$screen-small-min}) {
    @content;
  }
}

// Small tablets (portrait view)
$screen-medium-min: 768px;
@mixin medium {
  @media (min-width: #{$screen-medium-min}) {
    @content;
  }
}

// Tablets and small desktops
$screen-large-min: 992px;
@mixin large {
  @media (min-width: #{$screen-large-min}) {
    @content;
  }
}

// Large tablets and desktops
$screen-desktop-min: 1200px;
@mixin desktop {
  @media (min-width: #{$screen-desktop-min}) {
    @content;
  }
}

@import "src/scrollbar.scss";
@import "src/input.scss";
@import "src/HamburgerButton.scss";
@import "src/Menu.scss";
@import "src/Link.scss";
@import "src/ResultItem.scss";

html {
  font-size: 100%;

  @include medium {
    font-size: 112.5%;
  }
}

body {
  background-color: $solution-background;
  color: $light-text;
}

.block {
  position: absolute;
  width: $block-size;
  height: $block-size;
  background-size: 100%;
  border: 2px solid $belt-border;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translate(-$block-size/2, -$block-size/2 + $belt-width/2);
  z-index: 1;

  &-label {
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 13px;
    pointer-events: none;
    user-select: none;
  }
}

.root {
  display: grid;

  //grid-template-rows: minmax(fit-content, 100%) auto;
  //grid-template-columns: 1fr;

  grid-template-rows: min-content min-content auto;
  grid-template-areas:
    "search"
    "results"
    "solution";

  @include medium {
    grid-template-columns: 300px auto;
    grid-template-rows: min-content auto;
    grid-template-areas:
      "search solution"
      "results solution";
    //grid-template-rows: 1fr;
  }
  height: 100%;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.graph {
  grid-area: solution;
  position: relative;
  overflow-y: auto;
  @include scrollbar($solution-background);
}

.hover-card {
  position: absolute;
  background-color: $menu-background;
  color: $light-text;
  padding: $space;
  border-radius: 3px;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09),
    0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
    0 32px 16px rgba(0, 0, 0, 0.09);
  z-index: 6;
  display: none;

  &[data-show] {
    display: block;
  }
}

table {
  color: inherit;
}

.hint-text {
  position: absolute;
  right: 16px;
  bottom: 0;
  padding: $space-double;
  color: $light-text;
  opacity: 20%;
  user-select: none;
  z-index: -1;
  text-align: right;
}
