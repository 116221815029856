.hamburger-button {
  display: inline-block;
  cursor: pointer;
  margin-right: $space;
  margin-left: $space-double;

  @include medium {
    display: none;
  }
}

.hamburger-button__bar1,
.hamburger-button__bar2,
.hamburger-button__bar3 {
  width: 26px;
  height: 3px;
  background-color: $highlight;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 3px;
}

.hamburger-button[data-is-open] {
  .hamburger-button__bar1 {
    transform-origin: 0 50%;
    transform: rotate(45deg);
  }

  .hamburger-button__bar2 {
    opacity: 0;
  }

  .hamburger-button__bar3 {
    transform-origin: 0 50%;
    transform: rotate(-45deg);
  }
}
