@mixin scrollbar($track-color) {
  scrollbar-width: thin;
  scrollbar-color: $highlight $track-color;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $highlight;
  }
}
